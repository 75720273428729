<template>
  <validation-observer ref="form">
    <v-card-text>
      <v-container>
        <v-row>
          <v-col v-if="vehicle.itemImage" cols="12" lg="12" md="12" sm="12" class="my-2">
            <img :src="vehicle.itemImage" alt="Profile Image" class="profile__image" />
          </v-col>
          <v-col cols="12" lg="4" md="4" sm="4" class="info__wrapper my-2">
            <span class="bold">
              {{ $t('vehiclesPage.vehiclePlates') }}
            </span>
            <span>
              {{ vehicle.plate_number || '/' }}
            </span>
          </v-col>
          <v-col cols="12" lg="4" md="4" sm="4" class="info__wrapper my-2">
            <span class="bold">
              {{ $t('vehiclesPage.vehicleBrand') }}
            </span>
            <span>
              {{ vehicle.brand || '/' }}
            </span>
          </v-col>
          <v-col cols="12" lg="4" md="4" sm="4" class="info__wrapper my-2">
            <span class="bold">
              {{ $t('vehiclesPage.vehicleClass') }}
            </span>
            <span>
              {{ vehicle.vehicleClass.name || '/' }}
            </span>
          </v-col>
          <v-col cols="12" lg="4" md="4" sm="4" class="info__wrapper my-2">
            <span class="bold">
              {{ $t('vehiclesPage.passengersNumber') }}
            </span>
            <span>
              {{ vehicle.number_of_passengers || '/' }}
            </span>
          </v-col>
          <v-col cols="12" lg="4" md="4" sm="4" class="info__wrapper my-2">
            <span class="bold">
              {{
                user.organisation?.organisation_settings?.distance_unit == 'mi'
                  ? $t('vehiclesPage.fuelConsumptionMi')
                  : $t('vehiclesPage.fuelConsumption')
              }}
            </span>
            <span>
              {{ vehicle.fuel_consumption || '/' }}
            </span>
          </v-col>
          <v-col cols="12" lg="4" md="4" sm="4" class="info__wrapper my-2">
            <span class="bold">
              {{ $t('vehiclesPage.manufactureYear') }}
            </span>
            <span>
              {{ vehicle.year_of_manufacture || '/' }}
            </span>
          </v-col>
          <v-col cols="12" lg="4" md="4" sm="4" class="info__wrapper my-2">
            <span class="bold">
              {{
                user.organisation?.organisation_settings?.distance_unit == 'mi'
                  ? $t('vehiclesPage.priceMi')
                  : $t('vehiclesPage.price')
              }}
            </span>
            <span>
              {{ vehicle.price_per_km || '/' }}
            </span>
          </v-col>
          <v-col cols="12" lg="4" md="4" sm="4" class="info__wrapper my-2">
            <span class="bold">
              {{ $t('vehiclesPage.hourPrice') }}
            </span>
            <span>
              {{ vehicle.price_per_hour || '/' }}
            </span>
          </v-col>
          <v-col cols="12" lg="4" md="4" sm="4" class="info__wrapper my-2">
            <span class="bold">
              {{ $t('vehiclesPage.hourWaitingPrice') }}
            </span>
            <span>
              {{ vehicle.price_per_waiting_hour || '/' }}
            </span>
          </v-col>
          <v-col cols="12" lg="4" md="4" sm="4" class="info__wrapper my-2">
            <span class="bold">
              {{ $t('vehiclesPage.licence') }}
            </span>
            <span>
              {{ vehicle.licence || '/' }}
            </span>
          </v-col>
          <v-col cols="12" lg="4" md="4" sm="4" class="info__wrapper my-2">
            <span class="bold">
              {{ $t('vehiclesPage.color') }}
            </span>
            <span>
              {{ vehicle.color || '/' }}
            </span>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </validation-observer>
</template>

<script>
export default {
  name: 'VehicleGeneralInfo',
  props: ['vehicle', 'vehicleClasses', 'user'],
  data: () => ({
    loading: false,
  }),
};
</script>

<style scoped lang="scss">
.profile__image {
  height: 200px;
  width: auto;
}

.info__wrapper {
  span {
    display: block;

    color: #1b1d20;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 100% */

    &.bold {
      color: #444;

      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px; /* 88.889% */
      margin-bottom: 1rem;
    }
  }
}
</style>
